import React, { useState } from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { Container, Row, Col } from "react-grid-system"
import {
  BodySmall,
  Caption,
  Display,
  DisplaySmall,
  HeadingSmall,
  Lead,
} from "./typography"
import RichContentTextBlock from "./richContentTextBlock"

const Hero = ({ hero }) => {
  const [selectedCategory, setSelectedCategory] = useState(hero[0])
  // console.log(hero)
  function isCurrent(id) {
    return id === selectedCategory.id
  }
  return (
    <Wrapper>
      <CustomBackground>
        <Img fluid={selectedCategory.backgroundImage.fluid} />
      </CustomBackground>
      <Container>
        <Row>
          <Col>
            <Content>
              <Row>
                <Col lg={8}>
                  <Title>
                    <Caption color={"white"}>{selectedCategory.label}</Caption>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: selectedCategory.title,
                      }}
                    />
                    <div
                      dangerouslySetInnerHTML={{
                        __html: selectedCategory.subtitle,
                      }}
                    />
                  </Title>
                </Col>
              </Row>
              <Row>
                {hero.map(category => (
                  <CustomCol
                    key={category.id}
                    onMouseEnter={() => setSelectedCategory(category)}
                    xs={12}
                    md={4}
                    xl={3}
                  >
                    <LinkContent current={isCurrent(category.id)}>
                      <Link to={`/${category.slug}`}>
                        {isCurrent(category.id) && (
                          <CustomBackground>
                            <Img fluid={category.buttonImage.fluid} />
                          </CustomBackground>
                        )}
                        <ButtonContent>
                          <Caption>{category.buttonLabel}</Caption>
                          <HeadingSmall>{category.buttonTitle}</HeadingSmall>
                        </ButtonContent>
                        <BodySmall>Scopri di più</BodySmall>
                      </Link>
                    </LinkContent>
                  </CustomCol>
                ))}
              </Row>
            </Content>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  padding-top: 12rem;
  padding-bottom: 12rem;
  background: ${DesignTokens.colors.secondary[500]};
`

const Title = styled.div`
  & > * {
    margin-bottom: 2rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  color: #fff;
  h1 {
    font-size: 3.25rem;
    text-transform: lowercase;
    span {
      display: block;
      font-size: 5rem;
      text-transform: uppercase;
    }
    @media screen and (max-width: 767px) {
      font-size: 3rem;
      span {
        font-size: 4rem;
      }
    }
  }
  h2 {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.3;
  }
  p {
    text-transform: uppercase;
  }
`

const Content = styled.div`
  position: relative;
  & > * {
    margin-bottom: 4rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`
const ButtonContent = styled.div`
  width: 60%;
  height: 100%;
`

const LinkContent = styled.div`
  height: 100%;
  min-height: 12rem;
  position: relative;
  border: 1px solid white;
  padding: 2rem;
  background: ${props => (props.current ? "#ffffffcc" : "transparent")};
  a {
    text-decoration: none;
  }
  p,
  h4 {
    color: ${props =>
      props.current ? DesignTokens.colors.secondary[500] : "white"};
  }
`

const CustomBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  .gatsby-image-wrapper {
    height: 100%;
  }
`

const CustomCol = styled(Col)`
  @media screen and (max-width: 767px) {
    margin-bottom: 1rem;
  }
`

export default Hero
