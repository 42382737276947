import React, { useState } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import ProductThumb from "./productThumb"
import { Row, Col, Container } from "react-grid-system"
import DesignTokens from "./designTokens"
import { DisplaySmall } from "./typography"

const FeaturedProductBlock = ({ section }) => {
  // console.log(section)
  return (
    <Wrapper>
      <Container>
        <Row gutterWidth={0}>
          <Col>
            <Box>
              <DisplaySmall>{section.title}</DisplaySmall>
            </Box>
          </Col>
          {section.products.map(product => (
            <Col key={product.id}>
              <ProductThumb product={product} />
            </Col>
          ))}
        </Row>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const Box = styled.div`
  background-color: ${DesignTokens.colors.primary[500]};
  color: white;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 2rem;
`

export default FeaturedProductBlock
