import React, { useState } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import RichContentTextBlock from "./richContentTextBlock"
import { DisplaySmall, Heading, HeadingSmall } from "./typography"
import { Container, Row, Col } from "react-grid-system"
import { LinkButtonSecondary, LinkButtonTertiary } from "./button"

const CallToActionBlock = ({ section }) => {
  // console.log(section)
  return (
    <Wrapper>
      <CustomBackground>
        <Img fluid={section.backgroundImage.fluid} />
      </CustomBackground>
      <Container>
        <Row>
          <Col lg={6} push={{lg:6}}>
            <Content>
              <DisplaySmall color={"white"}>{section.title}</DisplaySmall>
              <HeadingSmall color={"white"}>{section.text}</HeadingSmall>
              <LinkButtonTertiary to={`/${section.slug}/`}>Scopri di più</LinkButtonTertiary>
            </Content>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  padding-top: 18rem;
  padding-bottom: 6rem;
  background: ${DesignTokens.colors.secondary[500]};
`

const Content = styled.div`
  text-align: right;
  position: relative;
  color: white;
  & > * {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const CustomBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  .gatsby-image-wrapper {
    height: 100%;
  }
`

export default CallToActionBlock
