import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { Container, Row, Col } from "react-grid-system"
import RichContentTextBlock from "./richContentTextBlock"

const ImageGridBlock = ({ section }) => {
  // console.log(section)
  return (
    <Wrapper>
      <Container>
        <Row>
          <Col xs={12} md={12} lg={8}>
            <Row>
              {section.featuredImage && (
                <ImageWrapper xs={12}>
                  <Img fluid={section.featuredImage.fluid} />
                </ImageWrapper>
              )}
              {section.images.map(image => (
                <ImageWrapper xs={6}>
                  <Img key={image.originalId} fluid={image.fluid} />
                </ImageWrapper>
              ))}
            </Row>
          </Col>
          <Col xs={12} md={12} lg={4}>
            <Box>
              <RichContentTextBlock html={section.text} />
            </Box>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const ImageWrapper = styled(Col)`
  margin-bottom: 2rem;
  @media screen and (max-width: 767px) {
    margin-bottom: 1rem;
  }
`

const Box = styled.div`
  background: #eee;
  padding: 3rem;
  display: flex;
  height: calc(100% - 2rem);
  align-items: center;
  @media screen and (max-width: 767px) {
    height: auto;
    padding: 1.5rem;
  }
`

export default ImageGridBlock
