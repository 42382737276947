import React from "react"
import { graphql } from "gatsby"
import { Layout } from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import ImageGridBlock from "../components/imageGridBlock"
import FeaturedProductBlock from "../components/featuredProductsBlock"
import CallToActionBlock from "../components/callToActionBlock"
import styled from "styled-components"

const IndexPage = ({ data: { page } }) => {
  // console.log(page)
  return (
    <Layout home>
      <SEO title="Home" />
      <Wrapper>
        <Hero hero={page.hero} />
        {page.content.map(section => (
          <section key={section.id}>
            {section.model.apiKey === "image_grid_block" && (
              <ImageGridBlock section={section} />
            )}
            {section.model.apiKey === "featured_products_block" && (
              <FeaturedProductBlock section={section} />
            )}
            {section.model.apiKey === "call_to_action_block" && (
              <CallToActionBlock section={section} />
            )}
          </section>
        ))}
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  & > * {
    margin-bottom: 3rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default IndexPage

export const query = graphql`
  query IndexQuery {
    page: datoCmsHomePage(locale: { eq: "en" }) {
      hero {
        buttonLabel
        buttonTitle
        id
        label
        slug
        subtitle
        title
        backgroundImage {
          fluid(maxWidth: 1920, imgixParams: { fm: "jpg" }) {
            ...GatsbyDatoCmsFluid
          }
        }
        buttonImage {
          fluid(maxWidth: 480) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
      content {
        ... on DatoCmsImageGridBlock {
          id
          images {
            originalId
            fluid(maxWidth: 480, imgixParams: { fm: "jpg" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          text
          model {
            apiKey
          }
        }
        ... on DatoCmsFeaturedProductsBlock {
          id
          title
          products {
            id
            slug
            name
            items {
              image {
                originalId
                fluid(maxWidth: 480, imgixParams: { fm: "jpg" }) {
                  ...GatsbyDatoCmsFluid
                }
              }
            }
            categories {
              name
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsCallToActionBlock {
          id
          backgroundImage {
            originalId
            fluid(maxWidth: 1920, imgixParams: { fm: "jpg" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          slug
          text
          title
          model {
            apiKey
          }
        }
      }
    }
  }
`
